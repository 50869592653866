import { Controller } from "stimulus";
import $ from "jquery";
import { uppyDashboard } from "@/uppy";
import prettyBytes from "@transloadit/prettier-bytes";
import { cropper } from "@/cropper";

export default class UploadController extends Controller {
  static targets = [ 'dashboard', 'previewBox', 'imagePreview', 'videoType', 'videoSize' ]

  connect() {
    this.uppy = this.createUppy()
  }

  disconnect() {
    this.uppy.close()

    if (this.cropper) this.cropper.destroy();
  }

  createUppy() {
    return uppyDashboard({
      id: this.data.get('identifier'),
      restrictions: {
        allowedFileTypes: this.allowedTypes,
        maxFileSize: this.maxSize,
      },
      target: this.dashboardTarget,
      height: 362,
      destination: this.data.get('destination'),
      locale: {
        youCanOnlyUploadFileTypes: `Allowed file types: ${this.humanTypes()}`,
      },
      onUploadSuccess: (file, uploadedFileData) => {
        this.uploadResult = uploadedFileData;
        this.dashboardTarget.style.display = 'none';

        if (this.hasImagePreviewTarget) {
          this.activateCropping(file)
        } else {
          this.videoPreview(file)
        }
      }
    })
  }

  activateCropping(file) {
    this.imagePreviewTarget.src = URL.createObjectURL(file.data);
    this.imagePreviewTarget.style.maxHeight = '100%';
    this.previewBoxTarget.style.display = 'block';

    this.cropper = cropper({
      image: this.imagePreviewTarget,
      crop: ({ width, height, x, y }) => {
        const imageData = this.uploadResult;
        imageData.metadata["cropping"] = { width, height, x, y }
        this.uploadResult = imageData;
      },
    })
  }

  videoPreview(file) {
    // console.log(file);

    this.previewBoxTarget.style.display = 'block';
    this.videoTypeTarget.innerHTML = file.type.split('/')[1].toUpperCase()
    this.videoSizeTarget.innerHTML = prettyBytes(file.size)
  }

  // convert ["image/jpeg", "image/png", ...] to "JPEG, PNG, ..."
  humanTypes() {
    return this.allowedTypes.map((mimeType) => mimeType.split('/')[1].toUpperCase()).join(', ');
  }

  get allowedTypes() {
    return this.data.get('types').split(',');
  }

  get maxSize() {
    return parseInt(this.data.get('max-size'));
  }

  get uploadResult() {
    return JSON.parse(this.uploadResultTarget.value);
  }

  set uploadResult(data) {
    this.uploadResultTarget.value = JSON.stringify(data);
  }

  get uploadResultTarget() {
    return document.getElementById(`${this.data.get('identifier')}-upload-result`);
  }
}
