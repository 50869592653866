import $ from "jquery";
import createSortable from "@/utils/sortable";

document.addEventListener("turbo:load", function() {
  if ($("#draggableCabinet").length > 0) {
    startCabinetSorter();
  }
});

function startCabinetSorter() {
  var sortable = createSortable(draggableCabinet, {
    onUpdate: function(evt) {
      var ourCollection = $(evt.item)
      var collectionId = evt.item.dataset.id;
      var collectionNewPosition = evt.newIndex;

      $.ajax({
        url: $("#draggableCabinet").data("url"),
        type: "POST",
        dataType: "script",
        data: {
          id: collectionId,
          newCabinetPosition: collectionNewPosition
        }
      });
    }
  }, true);
}
