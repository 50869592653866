import { Controller } from "stimulus";

export default class NameCabinetController extends Controller {
  att = null;

  initialize() {
    this.nameCabinetWizard();
  }

  nameCabinetWizard() {
    var cabinetNameInput = document.getElementById('cabinet_name');
    var cabinetNameSubmit = document.getElementById('submit-cabinet-name');
    var startingCabinetLength = cabinetNameInput.value.length;

    // Create a "disabled" attribute
    this.att = document.createAttribute("disabled");
    this.att.value = "disabled";

    if (startingCabinetLength <= 3) {
      this.disableTheButton(cabinetNameSubmit);
    } else {
      this.enableTheButton(cabinetNameSubmit);
    }

    cabinetNameInput.addEventListener('input', (e) => {
      const cabinetLength = cabinetNameInput.value.length;

      if (cabinetLength > 3) {
        this.enableTheButton(cabinetNameSubmit);
      }
      if (cabinetLength <= 3) {
        this.disableTheButton(cabinetNameSubmit);
      }
    });

    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
      tx[i].addEventListener("input", function () {
        this.style.height = "auto";
        this.style.height = (this.scrollHeight) + "px";
      }, false);
    }
  }

  disableTheButton(button) {
    button.setAttributeNode(this.att);
    button.value = "Next Step...";
  }

  enableTheButton(button) {
    button.removeAttribute("disabled");
    button.value = "Next Step: Create a Collection ->";
  }
}
